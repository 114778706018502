<template>
  <div class="d-flex justify-content-center align-items-center mx-auto p-4">
    <div class="contact d-flex flex-column flex-sm-row p-2">
      <form ref="form" class="p-4" @keyup="formValidation">
        <div>
          <p class="form-title text-center">{{ contaTexts.headMsg[lang] }}</p>
        </div>
        <div class="form-row ">
          <div class="col">
            <input
              v-model="forms.name"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.name,
                'is-valid': valid.name,
              }"
              v-if="lang === 'fr'"
              placeholder="Nom"
            />
            <input
              dir="rtl"
              v-model="forms.name"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.name,
                'is-valid': valid.name,
              }"
              v-else-if="lang === 'ar'"
              placeholder="اسم العائلة"
            />
            <input
              v-model="forms.name"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.name,
                'is-valid': valid.name,
              }"
              v-else
              placeholder="First name"
            />
          </div>
          <div class="col">
            <input
              v-model="forms.lastname"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.lastname,
                'is-valid': valid.lastname,
              }"
              v-if="lang === 'fr'"
              placeholder="Prénom"
            />
            <input
              dir="rtl"
              v-model="forms.lastname"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.lastname,
                'is-valid': valid.lastname,
              }"
              v-else-if="lang === 'ar'"
              placeholder="الاسم"
            />
            <input
              v-model="forms.lastname"
              type="text"
              name=""
              id=""
              class="form-control"
              :class="{
                'is-invalid': invalidation.lastname,
                'is-valid': valid.lastname,
              }"
              v-else
              placeholder="Last name"
            />
          </div>
        </div>
        <div class="form-group mt-2">
          <input
            v-model="forms.email"
            type="email"
            name="Email"
            id="Email"
            class="form-control"
            :class="{
              'is-invalid': invalidation.email,
              'is-valid': valid.email,
            }"
            v-if="lang != 'ar'"
            placeholder="Email"
          />
          <input
            dir="rtl"
            v-model="forms.email"
            type="email"
            name="Email"
            id="Email"
            class="form-control"
            :class="{
              'is-invalid': invalidation.email,
              'is-valid': valid.email,
            }"
            v-if="lang === 'ar'"
            placeholder="البريد الإلكتروني"
          />
        </div>
        <div class="form-group mt-2">
          <input
            v-model="forms.subject"
            type="text"
            name="text"
            id="text"
            class="form-control"
            :class="{}"
            v-if="lang === 'fr'"
            placeholder="Sujet"
          />
          <input
            dir="rtl"
            v-model="forms.subject"
            type="text"
            name="text"
            id="text"
            class="form-control"
            :class="{}"
            v-else-if="lang === 'ar'"
            placeholder="موضوع"
          />
          <input
            v-model="forms.subject"
            type="text"
            name="text"
            id="text"
            class="form-control"
            :class="{}"
            v-else
            placeholder="Subject"
          />
        </div>
        <div class="form-group mt-1">
          <textarea
            v-model="forms.message"
            class="form-control"
            :class="{
              'is-invalid': invalidation.message,
              'is-valid': valid.message,
            }"
            id="message"
            v-if="lang === 'fr'"
            placeholder="Tapez votre message ici..."
            rows="5"
          ></textarea>
          <textarea
            dir="rtl"
            v-model="forms.message"
            class="form-control"
            :class="{
              'is-invalid': invalidation.message,
              'is-valid': valid.message,
            }"
            id="message"
            v-else-if="lang === 'ar'"
            placeholder="اكتب رسالتك هنا..."
            rows="5"
          ></textarea>
          <textarea
            v-model="forms.message"
            class="form-control"
            :class="{
              'is-invalid': invalidation.message,
              'is-valid': valid.message,
            }"
            id="message"
            v-else
            placeholder="Write your message here..."
            rows="5"
          ></textarea>
        </div>
        <div class="recaptcha-mention">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
          <p
            class="text-center response"
            v-if="ResponseMessage.status !== ''"
            :class="ResponseStyling"
          >
            {{ ResponseMessage.msg }}
          </p>
        </div>
        <div class="text-center">
          <button class="btn btn-color" @click.prevent="submit">
            {{ contaTexts.submitMsg[lang] }}
          </button>
        </div>
      </form>
      <div class="contact-info p-2">
        <strong v-if="lang === 'fr'" class=""><i class="fas fa-map-marker-alt"></i> Adresse</strong>
        <strong v-else-if="lang === 'ar'" class="">عنوان <i class="fas fa-map-marker-alt"></i></strong>
        <strong v-else class=""><i class="fas fa-map-marker-alt"></i> Address</strong>
        <address v-if="lang === 'ar'">
          4089 171  شارع النخيل ص. ب<br/>
          ميناء القنطاوي حمام سوسة<br/>
          تونس 
          <br/>
        </address>
        <address v-else>
          Rue des Palmiers BP 171 4089 <br/>
          Port El Kantaoui Hammam-Sousse <br/>
          TUNISIE
          <br/>
        </address>
        <strong v-if="lang === 'fr'" class=""><i class="fas fa-phone"></i> Téléphone </strong>
        <strong v-else-if="lang === 'ar'" class="">هاتف <i class="fas fa-phone"></i></strong>
        <strong v-else class=""><i class="fas fa-phone"></i> Telephone </strong>
        <p v-for="tel in Tels" :key="tel">{{ tel }}</p>
        <strong v-if="lang === 'ar'" class="">البريد الإلكتروني <i class="far fa-envelope"></i></strong>
        <strong v-else class=""><i class="far fa-envelope"></i> Email</strong>
        <p v-for="email in emails" :key="email">{{ email }}</p>
        <img
          class="trip-img"
          src="@/assets/tripadvisor-certificate-2019.webp"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contaTexts: {
        headMsg: {
          fr: 'Contactez-nous !',
          en: 'Contact Us !',
          ar: '! اتصل بنا',
        },
        submitMsg: {
          fr: 'Envoyer',
          en: 'Submit',
          ar: 'إرسال',
        },
      },
      Tels: ["(+216) 73 348 855"],
      emails: ["acqua@acquapalace.com"],
      forms: {
        name: "",
        lastname: "",
        email: "",
        message: "",
        subject: "",
      },
      invalidation: {
        name: false,
        lastname: false,
        email: false,
        message: false,
        subject: false,
      },
      valid: {
        name: false,
        lastname: false,
        email: false,
        message: false,
        subject: false,
      },
      ResponseMessage: {
        status: "",
        msg: "",
      },
    };
  },
  methods: {
    async submit() {
      if (this.formValidation()) {
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha().catch(() => {
          return "Error generating Captcha token";
        });
        const forms = this.forms;
        this.axios
          .post(`https://${process.env.VUE_APP_HOST_IP}/api/contact/captchaSendMail`, {
            token, forms
          })
          .then(response => {
            this.success = response.data.status;
            if (this.success === "error captcha") {
              this.ResponseMessage.status = "error captcha";
              this.ResponseMessage.msg = "Erreur de la verification de la Captcha.";
               return ("Erreur de la verification de la Captcha.");
            }
            if (this.success === "Success") {
              this.ResponseMessage.status = "success";
              if (this.lang === 'fr')
                this.ResponseMessage.msg = "Message envoyer avec succès.";
              else if (this.lang === 'en')
                this.ResponseMessage.msg = "Message successfully sent."
              setTimeout(() => {
                this.router.push({ name: "accueil" });
              }, 3000);
              } else {
                this.ResponseMessage.status = "error";
                if (this.lang === 'fr')
                  this.ResponseMessage.msg = "Erreur d'envoi du message.";
                else if (this.lang === 'en')
                  this.ResponseMessage.msg = "Error sending the message."
                return ("Erreur d'envoi du message.");
              }
          })
          .catch(() => {
            this.ResponseMessage.status = "error";
            if (this.lang === 'fr')
              this.ResponseMessage.msg = "Une erreur est survenu. Veuillez réessayer ultérieurement !";
            else if (this.lang === 'en')
              this.ResponseMessage.msg = "An error has occured. Please retry later !";
          });
      }
    },
    formValidation() {
      let passed = true;
      if (!this.forms.name.match(/^[a-z ا-ي ئءؤ ,.'-]+$/i)) {
        this.invalidation.name = true;
        this.valid.name = false;
        passed = false;
      } else {
        this.invalidation.name = false;
        this.valid.name = true;
      }
      if (!this.forms.lastname.match(/^[a-z ا-ي ئءؤ ,.'-]+$/i)) {
        this.invalidation.lastname = true;
        this.valid.lastname = false;
        passed = false;
      } else {
        this.invalidation.lastname = false;
        this.valid.lastname = true;
      }
      if (!this.forms.message.match(/^[a-z0-9@\n?!éèàêâ&#/|~^,.'"-_() ا-ي ئءؤ ]+$/i)) {
        this.invalidation.message = true;
        this.valid.message = false;
        passed = false;
      } else {
        this.invalidation.message = false;
        this.valid.message = true;
      }
      if (!this.forms.email.match(/^[A-Z0-9+_.-]+@[A-Z0-9.-ا-ي]+$/i)) {
        this.invalidation.email = true;
        this.valid.email = false;
        passed = false;
      } else {
        this.invalidation.email = false;
        this.valid.email = true;
      }
      if (!this.forms.subject.match(/^[a-z ,.'-ا-ي ئءؤ]+$/i)) {
        this.invalidation.subject = true;
        this.valid.subject = false;
        passed = false;
      } else {
        this.invalidation.subject = false;
        this.valid.subject = true;
      }

      return passed;
    },
  },
  computed: {
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
    ResponseStyling() {
      return {
        "response-success": this.ResponseMessage.status === "success",
        "response-error": this.ResponseMessage.status === "error",
      };
    },
  },
};
</script>
<style src="./Contact.css"></style>
